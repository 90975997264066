import React from 'react';
import { Layout, Menu, Carousel, Image } from 'antd';
import {
    GlobalOutlined,
    FolderOpenOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import actionNepal from '../../image/donorLogo/action.png';
import techcoLab from '../../image/donorLogo/techco.png';
import dcaNepal from '../../image/donorLogo/dca.png';
const { Sider } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}
type sidebarProps = {
    isLoading: boolean
    collapsed: boolean
    setCollapsed: (data: any) => void
    menuClickHandler: ({ item, key, keyPath, domEvent }: any) => void
}

const items: MenuItem[] = [

    getItem('Maps', 'maps', <GlobalOutlined />, [
        getItem('Landslide', 'Landslide', null, [getItem('Aspect', '1'), getItem('Geology', '7')
            , getItem('Lineament Density', '8'), getItem('Land Cover', '9'), getItem('Landslide Susceptilibity', '10')
            , getItem('Field Point', '11'), getItem('Landslide Frequency', '12')
        ]),
        getItem('Flood', 'Flood', null, [getItem('Flood Area', '2'), getItem('Elevation', '3')
            , getItem('Slope in Degree', '4'), getItem('Drainage Density', '5'), getItem('Flood Susceptability', '6')]),


        getItem('Multi-Hazard', 'Multi-Hazard', null, [getItem('Multi-Hazard Susceptibility', '13'), getItem('Multi-Hazard Risk', '14')
            , getItem('Earthquake Susceptibility', '15'), getItem('Earthquake Frequency', '16'), getItem('Population Density', '17')
            , getItem('Road Density', '18'), getItem('Amenities 1', '19'), getItem('Amenities 2', '20')
        ]),


    ]),
    getItem('Documentation', 'documentation', <FolderOpenOutlined />, [getItem('Introduction', '21'), getItem('Data and methods', '22')
        , getItem('Result and Discussion', '23'), getItem('Conclusion', '24')]),
    // getItem('Files', '9', <FileOutlined />),
];


const Sidebar = ({ isLoading, collapsed, setCollapsed, menuClickHandler }: sidebarProps) => {
    return (
        <Sider width={280} breakpoint="sm" style={{ textAlign: 'left', position: 'relative', height: '100%' }} theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div className="logo" >
                EFFORT
            </div>
            {/* <div style={{ height: 32, margin: 16, background: 'rgba(255, 255, 255, 0.2)' }} /> */}
            <Menu style={{ height: '70%', overflowY: 'auto' }}
                theme="light" defaultSelectedKeys={['1']}
                defaultOpenKeys={['maps', 'Landslide']}
                mode="inline" items={items}
                disabled={isLoading ? true : false}
                onClick={menuClickHandler}
            />
            <div style={{ width: '100%', padding: '0px 35px' }} className={collapsed ? 'hideCarousel' : 'carouselShow'} >
                <Carousel autoplay dots={false}
                // style={{ display: collapsed ? 'none' : '' }} 
                >
                    <div>
                        <Image
                            width={200}

                            src={actionNepal}
                        />
                    </div>
                    <div>
                        <Image
                            width={200}

                            src={dcaNepal}
                        />
                    </div>
                    <div>
                        <Image
                            width={200}

                            src={techcoLab}
                        />
                    </div>

                </Carousel>
            </div>
        </Sider>
    )
}


export default Sidebar;
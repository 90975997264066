import React from 'react';
import { Layout, theme } from 'antd';
import {

    DownloadOutlined,

} from '@ant-design/icons';
const { Header } = Layout;




type propsType = {
    onClick: () => void
    categoryType: string,
    imageTitle: string
}

const Navbar = ({ categoryType, onClick, imageTitle }: propsType) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Header
            className='header'
            style={{
                background: colorBgContainer, display: 'flex', justifyContent: 'space-between'
            }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })} */}
                <span style={{ fontSize: '20px', fontWeight: '500', color: '#389E0D' }} >{categoryType === 'documentation' ? 'Documentation' : imageTitle}</span>
            </div>
            <div style={{ cursor: 'pointer' }} title='Download' onClick={onClick}>
                <span style={{ color: '#389E0D', fontSize: '14px', fontWeight: '400' }} >  Download</span>
                <DownloadOutlined style={{ marginLeft: '5px', marginRight: '5px', fontSize: '16px', color: '#00000', opacity: '0.85' }} />
            </div>
        </Header>
    )
}


export default Navbar
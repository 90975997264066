/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Image, Button } from 'antd';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
    PlusOutlined,
    MinusOutlined,
    ReloadOutlined
} from '@ant-design/icons';
type mapProps = {
    image: string

}

const MapImage = ({ image }: mapProps) => {

    return (
        <>
            <TransformWrapper
                maxScale={25}
                wheel={{ wheelDisabled: true }}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <div id="image-div" style={{
                        position: 'relative',
                        marginLeft: 'auto', marginRight: 'auto',
                        // width: 'calc(100vw - 335px)',
                        // width: '21cm',
                        // width: '595px',
                        minHeight: '70vh'
                    }} >
                        <React.Fragment>
                            <div className="tools" id="zoom-buttons">
                                <Button type="primary" color='#85A5FF' icon={<PlusOutlined />} onClick={() => zoomIn()} />
                                <Button type="primary" color='#85A5FF' icon={<MinusOutlined />} onClick={() => zoomOut()} />
                                <Button type="primary" color='#85A5FF' icon={<ReloadOutlined />} onClick={() => resetTransform()} />
                            </div>
                            <TransformComponent
                                wrapperClass='wrapperMainClass' contentClass='wrapperContentClass'
                            >
                                <Image
                                    preview={false}
                                    width={'100%'}
                                    // height={'70vh'}
                                    src={image}
                                    style={{ objectFit: 'contain' }}
                                />
                            </TransformComponent>
                        </React.Fragment>
                    </div>
                )}
            </TransformWrapper>

        </>
    )
}

export default MapImage
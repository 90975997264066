/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import '../../index.css';
import { Layout, theme, Typography, Card, Spin, Space } from 'antd';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import { apiIDMatchValue } from '../../utils/functions';
import { findSelectedData } from '../../utils/functions';
import MapImage from '../MapImage';

interface ChildProps {
    image_title?: string;
    image?: string;
    description_title?: string;
    description?: string;
}

const { Content } = Layout;

const { Text } = Typography;

const Dashboard: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [mapData, setMapData] = useState([]);
    const [selectedMapData, setSelectedMapData] = useState<{
        image_title: string, image: string, description_title: string, description: string
    }>({ image_title: '', image: '', description_title: '', description: '' });
    const [introduction, setIntroduction] = useState<{ id: Number; content: String }[]>([])
    const [dataMethod, setDataMethod] = useState<{ id: Number; content: String }[]>([])
    const [resultDiscussion, setResultDiscussion] = useState<{ id: Number; content: String }[]>([])
    const [conclusion, setConclusion] = useState<{ id: Number; content: String }[]>([])
    const [categoryType, setCategoryType] = useState<string>('');
    const [descriptionDisplay, setDescriptionDisplay] = useState<{ id: Number; content: String }[]>([])
    const [documentationHeading, setDocumentationHeading] = useState<string>('')


    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        function addScript(url: string) {
            const script = document.createElement('script');
            script.type = 'application/javascript';
            script.src = url;
            document.head.appendChild(script);
        }
        addScript('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js');
    }, []);
    const handleDownload = () => {
        const { description_title }: ChildProps
            = selectedMapData
        if (!isLoading) {
            setIsLoading(true)
            const myElements = document.getElementById('zoom-buttons');
            if (myElements) {
                myElements.style.setProperty('display', 'none', 'important');
            }
            const divToDisplay = document.getElementById('overallDownload');
            const options = {
                pagebreak: { mode: ['avoid-all'] },
                margin: [10, 0, 10, 0],
                html2canvas: { scale: 2, useCORS: true, allowTaint: false },

            };
            // @ts-ignore
            const reportPDF = html2pdf().set(options).from(divToDisplay).outputPdf('blob')
                .then((bulletin: Blob) => {

                    if (myElements) {

                        myElements.style.setProperty('display', 'block', 'important');
                    }
                    reportPDF.save(categoryType === 'documentation' ? `${documentationHeading}` : `${description_title}`)
                    setIsLoading(false)
                })
        }
    }
    const menuClickHandler = ({ item, key, keyPath, domEvent }: any) => {
        if (keyPath[1] === 'documentation') {
            const docHeading: any = apiIDMatchValue.find((i: { category: string; }): any => i.category === 'documentation')
                .subCategory.find((d: { id: any; }): any => d.id === keyPath[0])?.values
            setDocumentationHeading(docHeading)
            if (keyPath[0] === '21') {
                setDescriptionDisplay(introduction)
            } else if (keyPath[0] === '22') {
                setDescriptionDisplay(dataMethod)
            } else if (keyPath[0] === '23') {
                setDescriptionDisplay(resultDiscussion)
            } else {
                setDescriptionDisplay(conclusion)
            }
        }
        if (keyPath[1] !== 'documentation') {
            const data = findSelectedData(keyPath[1], keyPath[0], mapData)
            setSelectedMapData(data)
        }
        setCategoryType(keyPath[1])
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_PATH}/map`)
            .then((response): any => response.json())
            .then(data => {
                setMapData(data)
            })
        fetch(`${process.env.REACT_APP_URL_PATH}/introduction`)
            .then((response): any => response.json())
            .then(data => {
                setIntroduction(data)
            })

        fetch(`${process.env.REACT_APP_URL_PATH}/data-and-method`)
            .then((response): any => response.json())
            .then(data => {
                setDataMethod(data)
            })

        fetch(`${process.env.REACT_APP_URL_PATH}/result-and-discussion`)
            .then((response): any => response.json())
            .then(data => {
                setResultDiscussion(data)
            })

        fetch(`${process.env.REACT_APP_URL_PATH}/conclusion`)
            .then((response): any => response.json())
            .then(data => {
                setConclusion(data)
            })
        setIsLoading(false)

    }, [])

    useEffect(() => {
        if (mapData.length) {
            const data = findSelectedData('Landslide', '1', mapData)
            setSelectedMapData(data)
        }
    }, [mapData])

    const { image_title, image, description_title, description }: ChildProps
        = selectedMapData
    const { content }: any = descriptionDisplay.length && descriptionDisplay[0]
    const description1 = description.replace('<p>', '')
    const finalDescription = description1.replace('</p>', '')
    const textRegex = finalDescription.split(' ');
    return (
        <Layout className='container' >

            <Sidebar
                isLoading={isLoading}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                menuClickHandler={menuClickHandler}
            />

            <Layout className="site-layout">
                <Navbar
                    categoryType={categoryType}
                    onClick={handleDownload}
                    imageTitle={image_title} />
                <Space direction="vertical" style={{
                    width: '100%', height: '100%',
                    overflow: 'auto',
                }}  >
                    <Space>
                        <Spin tip={mapData.length
                            ? "Downloading,Please Wait..."
                            : 'Loading Data,Please Wait'}
                            size="large"
                            spinning={isLoading ? true : false} >
                            <Content
                                style={{
                                    margin: '24px 0px',
                                    background: colorBgContainer,
                                    minHeight: '85vh',
                                    width: '21cm'
                                }}
                                id="overallDownload"
                            >
                                {categoryType === 'documentation' ?
                                    <Card style={{
                                        textAlign: 'left', height: 'auto',
                                    }} title={documentationHeading}>
                                        <>
                                            <Text  >
                                                <div className='doc-body' dangerouslySetInnerHTML={{ __html: content }} />

                                            </Text>
                                        </>
                                    </Card> :
                                    <>
                                        <MapImage
                                            image={image}
                                        />

                                        <Card style={{
                                            textAlign: 'left', height: 'auto',
                                        }} title={description_title}>
                                            {textRegex?.length && textRegex.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <Text style={{ padding: i < 40 ? `${i + 1}px 0` : `40px 0` }} >
                                                        {`${item} `}
                                                    </Text>
                                                </React.Fragment>
                                            ))}
                                        </Card>
                                    </>}
                            </Content>
                        </Spin>
                    </Space>
                </Space>
            </Layout>
        </Layout >
    );
};

export default Dashboard;
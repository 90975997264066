
export const apiIDMatchValue: (any) = [
    {

        category: 'Flood',
        subCategory: [
            {
                id: "2",
                values: "Flood Area"
            },
            {
                id: '3',
                values: 'Elevation'
            },
            {
                id: '4',
                values: "Slope In Degree"
            },
            {
                id: '5',
                values: "Drainage Density"
            },
            {
                id: '6',
                values: "Flood Susceptibility"
            }
        ]
    },
    {

        category: 'Landslide',
        subCategory: [
            {
                id: "1",
                values: "Aspect"
            },
            {
                id: "7",
                values: "Geology"
            },
            {
                id: "8",
                values: "Lineament Density"
            },
            {
                id: "9",
                values: "Land Cover"
            },
            {
                id: "10",
                values: "Landslide Susceptilibity"
            },
            {
                id: "11",
                values: "Field Point"
            },
            {
                id: "12",
                values: "Landslide Frequency"
            },
        ]
    },
    {

        category: 'Multi-Hazard',
        subCategory: [
            {
                id: "13",
                values: "Multi-Hazard Susceptibility"
            },
            {
                id: "14",
                values: "Multi-Hazard Risk"
            },
            {
                id: "15",
                values: "Earthquake Susceptibility"
            },
            {
                id: "16",
                values: "Earthquake Frequency"
            },
            {
                id: "17",
                values: "Population Density"
            },
            {
                id: "18",
                values: "Road Density"
            },
            {
                id: "19",
                values: "Amenities 1"
            },
            {
                id: "20",
                values: "Amenities 2"
            },
        ]
    },
    {

        category: 'documentation',
        subCategory: [
            {
                id: "21",
                values: "Introduction"
            },
            {
                id: "22",
                values: "Data and methods"
            },
            {
                id: "23",
                values: "Result and Discussion"
            },
            {
                id: "24",
                values: "Conclusion"
            },
        ]
    }

]



export const findSelectedData = (category: any, subCategory: any, dataFromApi: any) => {
    const selectedData = apiIDMatchValue.find((i: { category: any; }): any => i.category === category)
        ?.subCategory.find((d: { id: any; }): any => d.id === subCategory)?.values;

    const selectedDataDetails = dataFromApi.find((d: { title: string | undefined; }): any => d.title === category)
        .children.find((itm: { title: any; }): any => itm.title === selectedData);
    return selectedDataDetails
}